<template>
  <div class="scenicdata">
    <Header class="hide" />
    <Headers class="dispy" />
    <div class="bar">
      <BannerImage rulename="scenicspot_banner" />
      <div class="w">
        <div class="info">
          <div class="tuo">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }"
                ><i class="el-icon-s-home"></i>首页</el-breadcrumb-item
              >
              <el-breadcrumb-item
                v-for="(item, index) in crumbdata"
                :key="index"
              >
                <span v-if="!item.url">{{ item.name }}</span>
                <router-link v-else :to="item.url">{{ item.name }}</router-link>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-row>
            <el-col :md="12">
              <img
                :src="`/jeecg-boot/${result.theLocal}`"
                alt=""
                class="tuo-img"
              />
            </el-col>
            <el-col :md="12">
              <div class="doc">
                <p class="title">{{ result.theTitle }}</p>
                <p class="doc-text">
                  <span v-if="result.theRank == 1">A</span>
                  <span v-if="result.theRank == 2">AA</span>
                  <span v-if="result.theRank == 3">AAA</span>
                  <span v-if="result.theRank == 4">AAAA</span>
                  <span v-if="result.theRank == 5">AAAAA</span>景区
                </p>
              </div>
              <div class="op">
                <p class="boder1"></p>
                <p class="phone">咨询电话：{{ result.theTel }}</p>
                <p class="boder1"></p>

                <p class="phone">门　　票：{{ result.theBill }}</p>
                <p class="boder1"></p>

                <p class="phone">景区地址：{{ result.theAdd }}</p>
                <p class="boder1"></p>

                <p class="phone">开放时间：{{ result.theTime }}</p>
                <p class="boder1"></p>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-row>
          <el-col :md="16">
            <div class="offer" v-if="result.theVideo">
              <div class="title">视频介绍</div>
              <el-divider></el-divider>
              <div class="video">
                <video
                  :src="`/jeecg-boot/sys/common/video/${result.theVideo}`"
                  width="100%"
                  height="100%"
                  controls="controls"
                  type="video/mp4"
                  object-fit=" fill"
                ></video>
              </div>
            </div>
            <div class="details">
              <div class="title">景区详情</div>
              <el-divider></el-divider>
              <div class="doc" v-html="result.theDetails"></div>
            </div>
            <div class="comment">
              <div class="title">游客点评</div>
              <el-divider></el-divider>
              <el-button type="success" @click="iwactComment"
                >我要点评</el-button
              >
              <div class="character" v-for="item in result.pl" :key="item.id">
                <div class="tou">
                  <img
                    v-if="item.avatar"
                    :src="`/jeecg-boot/${item.avatar}`"
                    alt="头像"
                    class="touxiang clear"
                  />
                  <img
                    v-else
                    src="@/assets/image/user/avatar.jpg"
                    alt="头像"
                    class="touxiang clear"
                  />
                  <div class="xx">
                    <span class="name">{{ item.username }}</span>
                    <span class="time">{{ item.sendTime }}</span>
                  </div>
                </div>
                <div class="favorable">
                  <div class="commonbody">{{ item.commentBody }}</div>
                  <el-row :gutter="20">
                    <el-col
                      :md="4"
                      v-for="(item, index) in stringTranarray(item.pic)"
                      :key="index"
                    >
                      <img
                        :src="`/jeecg-boot/${item}`"
                        class="favorable-image"
                      />
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :md="8">
            <div class="hotel">
              <div class="title">周边饭店</div>
              <router-link to="/hotel" class="titles">></router-link>
              <el-divider></el-divider>
              <ul>
                <li class="tup" v-for="item in result.fanDian" :key="item.id">
                  <router-link
                    :to="{ path: '/hotelDetail', query: { id: item.id } }"
                  >
                    <img
                      :src="`/jeecg-boot/${item.theLocal}`"
                      alt=""
                      class="bguan1"
                    />
                    <div class="name">
                      <span>{{ item.theName }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>

            <div class="hotel">
              <div class="title">周边娱乐</div>
              <div class="titles">></div>
              <el-divider></el-divider>
              <ul>
                <li class="tup" v-for="item in result.yuLe" :key="item.id">
                  <router-link
                    :to="{ path: '/entertdata', query: { id: item.id } }"
                  >
                    <img
                      :src="`/jeecg-boot/${item.theLocal}`"
                      alt=""
                      class="bguan1"
                    />
                    <div class="name">
                      <span>{{ item.theTitle }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <Footer />
    <!-- 点评弹框 -->
    <Commentbox ref="commentv" @change="commonHandler" />
  </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import BannerImage from "@/components/main/BannerImage";
import Header from "@/components/main/Header";
import Footer from "@/components/main/Footer";
import Pagi from "@/components/Pagi";
import { getAction, postAction } from "@/api/manage";
import Commentbox from "@/components/Commentbox";
import Headers from "@/components/wap/Headers";
export default {
  components: {
    Header,
    Footer,
    Pagi,
    Breadcrumb,
    BannerImage,
    Commentbox,
    Headers,
  },
  created() {
    this.loadData(1);
  },
  data() {
    return {
      crumbdata: [
        { name: "景点与路线", url: "" },
        { name: "旅游景点", url: "/scenicspot" },
      ],
      result: [],
      yulelist: "",
    };
  },
  methods: {
    loadData() {
      let params = {
        id: this.$route.query.id,
      };
      getAction("/scenery/tourScenery/queryScenicDetailById", params).then(
        (res) => {
          if (res.success) {
            this.result = res.result;
          }
        }
      );
    },
    // 点评
    iwactComment() {
      let token = localStorage.getItem("token");
      if (token) {
        this.$refs.commentv.open("lyjd");
      } else {
        this.$parent.$refs.login.visibles = true;
      }
    },
    commonHandler(value) {
      postAction("/forum/userForum/add", value).then((res) => {
        if (res.success) {
          this.$message.success("评论成功,审核中！");
          this.loadData();
        }
      });
    },
    // 字符串转换成数组
    stringTranarray(value) {
      let a = value.split(","),
        b = [];
      for (let i = 0; i < a.length; i++) {
        b.push(a[i]);
      }
      b.pop();
      return b;
    },
  },
};
</script>
<style lang="scss" scoped>
.scenicdata {
  .bars {
    width: 100%;
  }
  background: url(/img/white-bg.9771c1fc.png) no-repeat fixed top left;
  .info {
    background-color: #ffffff;
    margin-top: 30px;
    margin-bottom: 25px;
    padding-top: 16px;
    .tuo {
      margin-left: 18px;
    }
    .tuo-img {
      width: 511px;
      height: 385px;
      margin-left: 17px;
      margin-top: 21px;
      margin-bottom: 26px;
    }
    .doc {
      .title {
        font-family: MicrosoftYaHei;
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 29px;
        letter-spacing: 0px;
        color: #333333;
        margin-top: 45px;
        margin-left: 41px;
      }
      .doc-text {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 29px;
        letter-spacing: 0px;
        color: #6EB400;
        margin-top: 26px;
        margin-left: 41px;
      }
    }
    .op {
      .phone {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #666666;
        margin-left: 41px;
      }
      .boder1 {
        border: 1px dashed #dbdbdb;
        margin: 13px 13px;
        margin-left: 41px;
      }
    }
  }
  .offer {
    background-color: #ffffff;
    margin-bottom: 25px;
    padding-top: 32px;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
    }
    .el-divider--horizontal {
      width: 96%;
      margin: 0 0 0 16px;
    }
    .video {
      height: 500px;
      margin: 15px;
      background-color: #000;
    }
  }
  .details {
    background-color: #ffffff;
    margin-bottom: 25px;
    padding-top: 32px;
    .title {
      font-size: 22px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
    }
    .el-divider--horizontal {
      width: 96%;
      margin: 0 0 0 16px;
    }
    ::v-deep.doc {
      font-size: 14px;
      line-height: 40px;
      color: #666666;
      width: 728px;
      padding: 20px;
      p {
        img {
          width: 90% !important;
          height: auto;
        }
        span {
          img {
            width: 90% !important;
            height: auto !important;
          }
        }
      }
    }
  }
  .comment {
    background-color: #ffffff;
    margin-bottom: 25px;
    padding-top: 32px;
    padding-bottom: 38px;
    .title {
      font-size: 22px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
    }
    .el-divider--horizontal {
      width: 96%;
      margin: 0 0 0 16px;
    }
    .el-button--success {
      margin: 20px;
    }
    .character {
      margin-bottom: 10px;
      .tou {
        .xx {
          margin-left: 70px;
          margin-top: -53px;
        }
        .touxiang {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          margin: 0px 8px 17px 20px;
        }
        .name {
          font-size: 14px;
          line-height: 40px;
          color: #333333;
        }
        .time {
          font-size: 10px;
          line-height: 40px;
          color: #999999;
          margin-left: 10px;
        }
      }
      .favorable {
        padding: 0 68px;
        .commonbody {
          font-size: 14px;
          color: #666666;
          margin-bottom: 10px;
        }
        .favorable-image {
          width: 100%;
          height: 82px;
          border-radius: 3px;
        }
      }
    }
  }
  .hotel {
    background-color: #ffffff;
    margin-bottom: 25px;
    padding-top: 32px;
    padding-bottom: 15px;
    margin-left: 24px;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
    }
    .titles {
      font-family: MicrosoftYaHei;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
      float: right;
      margin-top: -41px;
      margin-right: 21px;
    }
    .el-divider--horizontal {
      width: 91%;
      margin: 0 0 0 16px;
    }
    .tup {
     margin: 15px 15px 0 15px;
      position: relative;
      height: 217px;
      width: 337px;
      overflow: hidden;
      .bguan1 {
        width: 100%;
        height: 100%;
        transition: all 0.6s;
        &:hover {
          transform: scale(1.1);
        }
      }
      .name {
        height: 35px;
        background-color: #00000096;
        text-align: center;
        position: relative;
        top: -39px;
        span {
          font-family: MicrosoftYaHei;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 29px;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
